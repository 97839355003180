import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 임시계정관리 화면 관련 기능 클래스
 */
class Account {

    /**
     * 생성자
     */
    constructor() {

        this.boardInfo = 'SYSTEM';
        this.searchVal = '';
        this.useYn = {UseYn: 'Y'};
        this.newPassword = '' ;
    }

    /**
     * 임시계정 목록 조회 
     * @returns 
     */
     getAccountInfoList() {

        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.UserInfoBiz';
            request.methodId = 'GetPersonalInfoIm';
            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows

                if (!!rows.length) resolve(rows);
                else resolve(null);

            }).catch(error => reject(error));
        });
    }

    /**
     * 임시계정 그룹옵션 조회 
     * @param {String} useYn
     * @returns 
     */
    getAuthorityGroupInfo() {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'GetAuthorityGroupInfo';

            request.parameters = this.useYn;

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);

            }).catch(error => reject(error));
        });
    }

    /**
     * 임시계정 중복체크
     * @param {string} emplID 
     * @returns 
     */
     getDuplicationAccountInfo(emplID) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.UserInfoBiz';
            request.methodId = 'GetUserInfoDuplication';

            request.parameters.userNo = emplID;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 임시계정 저장
     * @param {Object} userInfo 
     * @returns 
     */
    saveAccountInfo(userInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.UserInfoBiz';
            request.methodId = 'SaveUserInfo';      
            request.parameters = userInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 임시계정 삭제
     * @param {Object} removeEmplID
     * @returns 
     */
     deleteAccountInfo(removeEmplID) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.UserInfoBiz';
            request.methodId = 'DeleteUserInfo';

            request.parameters.str = JSON.stringify(removeEmplID);

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 임시계정 로그 저장
     * @param {Object} userLogInfo 
     * @returns 
     */
     addUserInfoHistory(userLogInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.CommonBiz';
            request.methodId = 'AddUserInfoHistory';

            request.parameters = userLogInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * ID 전환 목록 조회
     * @param {string} searchKeyword 
     * @returns 
     */
     getUserInfoADList(searchKeyword) {

        searchKeyword  = !!searchKeyword ? searchKeyword : '';

        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.UserInfoBiz';
            request.methodId = 'GetUserInfoADList';

            request.parameters.EmplName = searchKeyword;

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows

                if (!!rows.length) resolve(rows);
                else resolve(null);

            }).catch(error => reject(error));
        });
    }

    /**
     * ID 전환
     * @param {Object} userInfo 
     * @returns 
     */
     transIdUserInfo(userInfo) {

        var paramsObj = new Object();

        paramsObj = {
            EmplID: userInfo.accountEmplID
            , EmployeeNoNew: userInfo.EmplID
        }
        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.UserInfoBiz';
            request.methodId = 'TransIDUserInfo';      
            request.parameters = paramsObj;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }
}

export const account = new Account();