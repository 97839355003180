<template>
    <div class="admin-content">
        <h3>임시계정관리</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button class="btn-box green"
                        @click="{
                            state.dialog.isOpen=true;
                            state.dialog.isNew=true;
                        }">계정생성</button>

                    <button class="btn-box red" @click="methods.deleteAccountInfo">계정삭제</button>
                </div>
            </div>
        </div>

        <!-- 총 개수 -->
		<div class="total-box">
			<p>Total <strong>{{state.dataTable.accountItems === null ? 0 : state.dataTable.accountItems.length}}</strong></p>
		</div>

        <DataTable class="p-datatable-sm"
            columnResizeMode="fit"
            scrollHeight="497px"
            :value="state.dataTable.accountItems"
            :paginator="true"
            :rowHover="true"
            :rows="15"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            @row-click="eventHandler.dataTable.onRowClick"
            v-model:selection="state.dataTable.rowDataModel2"
            dataKey="EmplID"
            :resizableColumns="true">

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column selectionMode="multiple" style="text-align:center; width:30px;" headerClass="tc"></Column>
            <Column field="RegtDateTime" header="등록일" :sortable="true" style="text-align:center; width:200px;" headerClass="tc"></Column>
            <Column field="EmplID" header="사용자 ID" :sortable="true" style="width:350px;" headerClass="tc"></Column>
            <Column field="EmplName" header="사용자명" :sortable="true" style="width:350px;" headerClass="tc"></Column>
            <Column field="GroupName" header="그룹명" :sortable="true" style="width:350px;" headerClass="tc"></Column>

            <Column style="width:50px">
                <template #body="{data}">
                    <div style="display: flex; justify-content: center;">
                        <button type="button" class="btn-box" @click="{
                            state.dialog2.isOpen=true;
                            state.dataTable.selectedEmplID = data.EmplID;
                        }">전환 </button>
                    </div>
                </template>
            </Column>

        </DataTable>

        <Dialog
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">
        
            <div class="dialog-wrap1">
                <div>
                    <label>사용자 ID <i class="required">*</i></label>
                    <div class="p-inputgroup">
                        <input type="text" v-model.trim="state.dataTable.rowDataModel.EmplID" :disabled="!state.dialog.isNew" class="input-text mr10" ref="emplID" maxlength="50"  
                        @change="eventHandler.dialog.changeEmplID" />
                    </div>
                    <!-- <div>
                        <div>
                            <div class="form-btn-box">
                                <input type="text" v-model.trim="state.dataTable.rowDataModel.EmplID" :disabled="!state.dialog.isNew" class="p-inputtext" ref="emplID" maxlength="50" />
                                <div class="btn-wrap">
                                    <button type="button" class="btn-box green">중복체크</button>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div>
                    <label>사용자 이름 <i class="required">*</i></label>
                    <div>
                        <input type="text" v-model.trim="state.dataTable.rowDataModel.EmplName" class="input-text mr10" ref="emplName" maxlength="30" />
                    </div>
                </div>
                <div>
                    <label>비밀번호 <i class="required">*</i></label>
                    <div>
                        <input type="password" v-model.trim="state.dataTable.rowDataModel.Password" class="input-text mr10" ref="password" maxlength="225" />
                    </div>
                </div>
                <div v-show="state.dialog.isNew">
                    <label>그룹지정 <i class="required">*</i></label>
                    <div class="select-box">
                        <select v-model.trim="state.dataTable.selectedCode"  ref="groupName">
                            <option v-for="item in state.dropDown.items" v-bind:value="item.CodeVal" v-bind:key="item" v-text="item.CodeName" />
                        </select>
                    </div>
                </div>
            </div>
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button class="btn-box green" v-show="state.dialog.isNew" @click="methods.getDuplicationAccountInfo">중복체크</button>
                        <button v-show="state.dialog.isNew" class="btn-box gray" @click="methods.accountValidation">생성</button>
                        <button v-show="!state.dialog.isNew" class="btn-box gray" @click="methods.accountValidation">수정</button>
                    </div>
                </div>
            </div>
        </Dialog>
    
        <Dialog
            :header="state.dialog2.header"
            :visible="state.dialog2.isOpen"
            :modal="true"
            @show="eventHandler.dialog2.show"
            @hide="eventHandler.dialog2.hide"
            @close="state.dialog2.isOpen=false"
            @update:visible="state.dialog2.isOpen = false">

            <div>
                <div class="p-inputgroup">
                    <input type="text" class="input-text mr10" v-model.trim="state.dataTable.searchKeyword" @keyup.enter="methods.getUserInfoADList" placeholder="사용자 이름 / ID" />
                    <button class="btn-box" @click="methods.getUserInfoADList" >조회 </button>
                    <br>
                </div>
            </div> 
            
            <!-- 총 개수 -->
            <div class="total-box">
                <p>Total <strong>{{state.dataTable.accountTransformItems === null ? 0 : state.dataTable.accountTransformItems.length}}</strong></p>
            </div>
            
            <DataTable class="p-datatable-sm"
                columnResizeMode="fit"
                scrollHeight="497px"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :value="state.dataTable.accountTransformItems"
                :paginator="true"
                :rowHover="true"
                :rows="8"
                :resizableColumns="true">
        
                <template #empty>
                    데이터가 없습니다.
                </template>
            
                <Column field="Check" header="선택" :sortable="true" style="text-align:center; width:30px;" headerClass="tc">
                    <template #body = {data}>
                        <input type="radio" v-model.trim="state.dataTable.rowDataModel3" :id="data.EmplID" :value="data"/>
                    </template> 
                </Column>
                <Column field="EmplID" header="사용자 ID" :sortable="true" style="text-align:center; width:100px;" headerClass="tc"></Column>
                <Column field="EmplName" header="사용자명" :sortable="true" style="text-align:center; width:100px;" headerClass="tc"></Column>
                <Column field="Department" header="부서명" :sortable="true" style="text-align:center; width:100px;" headerClass="tc"></Column>
            </DataTable>
            
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button class="btn-box" @click="methods.transIdUserInfo">전환</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { account } from '@/modules/site-training/account-index.js';
import { common } from '@/modules/common/common.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';

export default {
    components: { },
    setup() {

        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const emplID = ref(); // 사용자 ID 
        const emplName = ref(); // 사용자 이름
        const password = ref(); // 비밀번호
        const groupName = ref(); // 그룹지정
        
        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 게시판 목록
                accountItems: null,

                // ID전환 목록
                accountTransformItems: null,

                // 선택한 임시계정 ID
                selectedEmplID: '',

                // 임시계정 그룹옵션 코드
                selectedCode: "C000001",

                // 행 데이터 모델 임시계정 저장 수정시 사용
                rowDataModel: new Object(),

                // 임시계정 삭제시 체크박스 중복선택 삭제가 가능함으로 배열로 지정
                rowDataModel2: new Array(),

                //ID 전환 라디오 선택 바인딩
                rowDataModel3: new Array(),

                //검색어
                searchKeyword: ''

            },

            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "임시 계정 생성",

                // 다이얼로그 오픈여부
                isOpen: false,

                // 신규 여부
                isNew: false,
            },

            dialog2: {

                // 다이얼로그 헤더 텍스트
                header: "ID 전환",

                // 다이얼로그 오픈여부
                isOpen: false,

                // 신규 여부
                isNew: false,
            },

            dropDown: {

                items: new Array(),

            },
            
        });

        // 메서드
        const methods = {

            // 임시계정 목록 조회
            getAccountInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                account.getAccountInfoList().then(result => {

                    state.dataTable.accountItems = result;

                }).finally(() => {
                    
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                });
            },

            // 임시계정 중복체크
            getDuplicationAccountInfo: () => {

                // 사용자ID가 입력이 안된경우.
                if (!state.dataTable.rowDataModel.EmplID) {

                    alertManager.alert.warning('사용자ID를 입력해주세요.');
                    return;
                }

                // 사용자ID 정규 체크
                else if (common.idRegularExpression(state.dataTable.rowDataModel.EmplID) === false) return;

                else {
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
                    
                    account.getDuplicationAccountInfo(state.dataTable.rowDataModel.EmplID)
                    
                    .then((response) => {

                        if(response.data.success) {

                            let result = response.data.result.value;

                            if (result == 0) {

                                state.dataTable.rowDataModel.checkUserIDYn = true;
                                alertManager.alert.success('사용이 가능한 ID입니다.');

                            } else {

                                state.dataTable.rowDataModel.checkUserIDYn = false;
                                alertManager.alert.warning('사용이 불가능한 ID입니다.');

                            }

                        }

                    })

                    .finally(() =>{

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                    });
                }
            },


            // 임시계정 저장
            saveAccountInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                account.saveAccountInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.data.success) {
        
                        alertManager.toast.save();

                        //생성 로그 저장 
                        methods.addUserInfoHistory("C", state.dataTable.rowDataModel.EmplID);

                        // 목록 재조회
                        methods.getAccountInfo();
                    }

                })
                    
                .finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                    
                    // 팝업 닫기
                    state.dialog.isOpen = false;
                });
                
            },

            // 임시계정 삭제
            deleteAccountInfo: () => {

                const removeEmplID = new Array();

                if (!state.dataTable.rowDataModel2.length) {

                    alertManager.alert.warning('삭제할 데이터가 없습니다.');
                    return;
                }

                alertManager.confirm.remove().then(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    
                    state.dataTable.rowDataModel2.forEach(item => removeEmplID.push({ EmplID : item.EmplID }));

                    account.deleteAccountInfo(removeEmplID).then((response) => {

                        if(response.data.success) {
                            
                            alertManager.toast.remove();

                        }
                        
                    }).finally(() =>{

                        removeEmplID.forEach(item => {
                        methods.addUserInfoHistory("D", item.EmplID); 

                        })

                        //체크한 데이터 초기화 
                        state.dataTable.rowDataModel2 = new Array();

                        // 목록 재조회
                        methods.getAccountInfo();

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                    });
                });
            },

            // 임시계정 로그 저장
            addUserInfoHistory: (type, emplID) => {

                let logInfo = new Object();

                if (type == 'C') { 
                    logInfo.logType = 'C000011';
                }

                else if (type == 'R') {
                    logInfo.logType = 'C000012';
                }
                else if (type == 'U')
                logInfo.logType = 'C000013';
                
                else if (type == 'D') {
                    logInfo.logType = 'C000014';   
                }

                logInfo.emplID = emplID;
                logInfo.device = !store.getters.isMobile ? 'C000018' : 'C000019';
                logInfo.regtEmplID = store.getters.getUserId;
                

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                account.addUserInfoHistory(logInfo)
                    
                .then((response) => {

                    if(response.data.success) {

                        console.log("로그 저장되었습니다. data : %o", logInfo);
                    }

                })
                    
                .finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                });
                
            },

            // 임시계정 정보 유효성검사
            accountValidation: () => {

                let validator = state.dataTable.rowDataModel;

                //임시계정 그룹옵션 선택값 전달 
                state.dataTable.rowDataModel.ParentCode = state.dataTable.selectedCode;
                
                // 사용자 ID
                if (!validator.EmplID) {

                    emplID.value.focus();
                    alertManager.alert.warning('사용자 ID를 입력해주세요');
                }
                // 중복체크
                else if (!validator.checkUserIDYn && !validator.updateSave) {

                    alertManager.alert.warning('사용자ID를 중복체크해 주세요.');
                }
                // 사용자 이름
                else if (!validator.EmplName) {

                    emplName.value.focus();
                    alertManager.alert.warning('사용자 이름을 입력해주세요');
                }
                // 비밀번호
                else if (!validator.Password) {

                    password.value.focus();
                    alertManager.alert.warning('비밀번호를 입력해주세요');
                }
                // 비밀번호 정규식 - 글자수 8글자 제한, 영어 숫자 특수문자 조합
                else if (!common.passwordRegularExpression(validator.Password)) {
                    return;
                }
                // 그룹지정
                else if (!validator.ParentCode) {

                    groupName.value.focus();
                    alertManager.alert.warning('그룹지정을 선택해주세요');
                }
                else {

                    // 임시계정 상세 저장
                    methods.saveAccountInfo();
                }
            },

            // ID 전환 목록 조회
            getUserInfoADList: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                account.getUserInfoADList(state.dataTable.searchKeyword).then(result => {

                    state.dataTable.accountTransformItems = result;

                }).finally(() => {
                    
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                });
            },

            // ID 전환
            transIdUserInfo: () => {

                // 라디오 버튼 미선택시
                if (!state.dataTable.rowDataModel3.EmplID) {

                    alertManager.alert.warning('사용자를 선택해주세요.');
                }
                else {
                    //선택한 임시계정 값 전달
                    state.dataTable.rowDataModel3.accountEmplID = state.dataTable.selectedEmplID;

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
                    
                    account.transIdUserInfo(state.dataTable.rowDataModel3).then((response) => {

                        if(response.data.success) {
                        
                            alertManager.toast.save();

                            //수정 로그 저장
                            methods.addUserInfoHistory("U", state.dataTable.rowDataModel3.EmplID);
                        }

                    })

                    .finally(() =>{

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                        // 팝업 닫기
                        state.dialog2.isOpen = false;

                        // 임시계정 조회
                        methods.getAccountInfo();

                        // ID 전환 목록 조회
                        methods.getUserInfoADList();
                    });
                }  
            },
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {
                
                // 행 클릭 이벤트 처리
                onRowClick: event => {

                    // 행 데이터 모델 설정
                    state.dataTable.rowDataModel = Object.assign({}, event.data);

                    // 팝업 오픈
                    state.dialog.isOpen = true;

                    // 수정 체크
                    state.dataTable.rowDataModel.updateSave = true;

                    //조회 로그 저장
                    methods.addUserInfoHistory("R", state.dataTable.rowDataModel.EmplID);
                
                },
            },

            // 임시계정 상세 다이얼로그 관련 이벤트 핸들러
            dialog: {

                // show 이벤트 처리
                show: () => {

                    //중복체크 여부 초기화
                    state.dataTable.rowDataModel.checkUserIDYn = false;

                    // 신규 다이얼로그 모드
                    if(state.dialog.isNew) {

                        //수정여부
                        state.dataTable.rowDataModel.updateSave = false;

                        //그룹옵션 코드 초기화
                        state.dataTable.selectedCode = "C000001";
                    }
                },

                // hide 이벤트 처리
                hide: () => {

                    // 행 데이터모델 초기화
                    state.dataTable.rowDataModel = new Object();
                    state.dialog.isNew = false;
                    state.dialog.checkUserIDYn = false;
                    state.dataTable.rowDataModel.updateSave = false;
                },

                // 임시계정 상세 사용자 EmplID 변경 이벤트
                changeEmplID: () => {
                    state.dataTable.rowDataModel.checkUserIDYn = false;
                },
    
            },

             // ID 전환 다이얼로그 관련 이벤트 핸들러
            dialog2: {

                // show 이벤트 처리
                show: () => {
                    methods.getUserInfoADList();
                },

                // hide 이벤트 처리
                hide: () => {

                    //검색어 초기화
                    state.dataTable.searchKeyword = '';

                    //라디오 선택 초기화
                    state.dataTable.rowDataModel3 = new Array();

                    //선택한 임시계정 ID 초기화 
                    state.dataTable.selectedEmplID = '';
                },
            },
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 임시계정 초기조회
            methods.getAccountInfo();

            // ID 전환 목록 초기조회
            methods.getUserInfoADList();

            //임시계정 그룹옵션 조회 
            account.getAuthorityGroupInfo().then(result => {

                state.dropDown.items = result;
            });
        });

        return {
            state,
            account,
            methods,
            eventHandler,
            emplID,
            emplName,
            password,
            groupName,
        }
    }
}
</script>